/**
 * CanoeDocumentService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface CanoeDocument { 
    /**
     * 
     */
    canoeDocumentId: string;
    /**
     * 
     */
    canoeLastModified: string;
    /**
     * 
     */
    canoeLastModifiedBy: string;
    /**
     * 
     */
    canoeUploaded: string;
    /**
     * 
     */
    category: CanoeDocument.CategoryEnum;
    /**
     * 
     */
    documentApproval: CanoeDocument.DocumentApprovalEnum;
    /**
     * 
     */
    documentName: string;
    /**
     * 
     */
    documentStatus: CanoeDocument.DocumentStatusEnum;
    /**
     * 
     */
    documentType: CanoeDocument.DocumentTypeEnum;
    /**
     * 
     */
    fileType: CanoeDocument.FileTypeEnum;
    /**
     * 
     */
    originalFileName: string;
    /**
     * 
     */
    source: string;
    /**
     * 
     */
    reportDate: string;
    /**
     * 
     */
    dueDate: string;
    /**
     * 
     */
    commitment: number;
    /**
     * 
     */
    fundName: string;
    /**
     * 
     */
    entityId: string;
    /**
     * 
     */
    paidInCapital: number;
    /**
     * 
     */
    intacctInvestmentId: string;
    /**
     * 
     */
    intacctEntityId: string;
    /**
     * 
     */
    intacctBatchId: string;
    /**
     * 
     */
    boxDocmentId: string;
    /**
     * 
     */
    capitalCallAmount: number;
}
export namespace CanoeDocument {
    export type CategoryEnum = 'CapitalActivity';
    export const CategoryEnum = {
        CapitalActivity: 'CapitalActivity' as CategoryEnum
    };
    export type DocumentApprovalEnum = 'Pending' | 'Approved';
    export const DocumentApprovalEnum = {
        Pending: 'Pending' as DocumentApprovalEnum,
        Approved: 'Approved' as DocumentApprovalEnum
    };
    export type DocumentStatusEnum = 'Received' | 'Missing' | 'PartiallyMissing';
    export const DocumentStatusEnum = {
        Received: 'Received' as DocumentStatusEnum,
        Missing: 'Missing' as DocumentStatusEnum,
        PartiallyMissing: 'PartiallyMissing' as DocumentStatusEnum
    };
    export type DocumentTypeEnum = 'CapitalCall' | 'Distributions' | 'ReturnOfCapital';
    export const DocumentTypeEnum = {
        CapitalCall: 'CapitalCall' as DocumentTypeEnum,
        Distributions: 'Distributions' as DocumentTypeEnum,
        ReturnOfCapital: 'ReturnOfCapital' as DocumentTypeEnum
    };
    export type FileTypeEnum = 'PDF' | 'Word' | 'Excel';
    export const FileTypeEnum = {
        Pdf: 'PDF' as FileTypeEnum,
        Word: 'Word' as FileTypeEnum,
        Excel: 'Excel' as FileTypeEnum
    };
}


